@import 'normalize';
@import 'variables';
@import 'mixin';
@import 'function';

* {
  font-family: 'Noto Sans TC', MicrosoftJhengHei, "微軟正黑體", "Microsoft YaHei", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: get-vw(16px);
}

html,
body,
section {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  background-repeat: repeat-y;
  background-position: center;
  background-size: cover;
  touch-action: manipulation;
}
body {
  background-image: url('../images/BG_COLOR.png');
}
body.auto,
section.auto {
  height: auto;
}

section {
  display: none;
}

section.main-page {
  display: block;
  background-image: url('../images/BG_COLOR.png');
}

section.photowall-page {
  position: absolute;
  
  background-image: url('../images/BG.jpg');
}

section.photowall-page.up-zindex {
  z-index: 1;
}

.photowall-block {
  display: none;
  float: right;
  width: 75%;
  height: 100%;
  background-color: rgba(20, 20, 22, 0.83);
  position: relative;
  overflow: hidden;
}

.photowall-block .photoblock {
  position: absolute;
  width: 400px;
  height: 400px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.photowall-block .photoblock.maskFadeIn {
    animation: maskFadeIn;
    animation-duration: .8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
.photowall-block .photoblock.maskFadeout {
  animation: maskFadeout;
  animation-duration: .8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

// .photoblock img {
//   height: 100%;
//   width: 100%;
// }

.layer {
   pointer-events: auto;
}

.photowall-block .photoblock.photoblock-1 {
  left: 10%;
  top: 10%;
  // width: 300px;
}
.photowall-block .photoblock.photoblock-2 {
  left: 40%;
  top: 30%;
  // width: 550px;
  // height: 550px;
}
.photowall-block .photoblock.photoblock-3 {
  left: 70%;
  top: 15%;
  // width: 250px;
}
.photowall-block .photoblock.photoblock-4 {
  left: 5%;
  top: 50%;
  // width: 300px;
}
.photowall-block .photoblock.photoblock-5 {
  left: 75%;
  top: 65%;
  // width: 250px;
}

.photowall-block .photoblock.photoblock-6 {
  left: 25%;
  top: 75%;
  // width: 500px;
}

.photowall-block .photoblock.photoblock-7 {
  left: 35%;
  top: 5%;
  width: 450px;
  height: 450px;
  // width: 500px;
}

.photowall-block .photoblock.photoblock-8 {
  left: 50%;
  top: 55%;
  // width: 350px;
}

.show-image {
  display: none;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60%;
  height: 70%;
  background-color: rgb(68, 68, 68);
  margin: auto;
  margin-left: 30%;
  padding: 20px;
  // padding-bottom: 80px;
}
.show-image .content-block {
  // outline: 1px solid red;
  height: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.show-image img {
  position: static;
  width: 100%;
  height: auto;
  max-height: 100%;

}
.close-image-btn {
  width: 70px;
  height: 70px;
  background-color: transparent;
  background-image: url(../images/CLOSE_WHITE.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  right: 20px;
}

section.tv-level {
  position: absolute;
  top: 0;
  background-image: url('../images/TV.png');
}

section.tv-click {
  // display: block;
  position: absolute;
  left: 55%;
  top: 55%;
  transform: translate(-55%,-60%) rotate(-18deg) skewX(-18deg);
  // outline: 1px solid red;
  width: 27.5%;
  height: 27.5%;
  z-index: 1;
}
section.tv-click:hover {
  cursor: pointer;
}

section.menu-content {
  position: absolute;
  top: 0;
  background-image: url('../images/MENU_BG1.png');
  z-index: 2;
}

.menu-title {
  color: rgb(255, 217, 161);
  text-align: center;
}

.tw-text {
  font-weight: bold;
}

.en-text {
  font-size: 16px;
  margin: 10px 0;
}

.menu-content ul,
.menu-footer {
  width: 60%;
  margin: auto;
  margin-top: 10%;
}

.menu-footer {
  margin-top: 5%;
}

.menu-footer a:hover,
.menu-footer button:hover {
  cursor: pointer;
}

.menu-content ul {
  margin-left: 20%;
}

.menu-content li {
  vertical-align: top;
  display: inline-block;
  width: 19%;
}

.a-group {
  margin-left: 27%;
}

.menu-content a {
  color: #FFF;
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 0.8em;
  margin-top: 20px;
  margin-bottom: 20px;
}

.menu-content a:hover {
  color: rgb(160, 160, 160);
}

.menu-content .a-group a {
  text-align: left;
}

// .tv-click {
//   display: none;
//   position: absolute;
//   left: 55%;
//   top: 55%;
//   transform: translate(-55%,-60%) rotate(-18deg) skewX(-18deg);
//   font-size: 40px;
//   padding: 5%;
// }

.main-block {
  width: 20%;
  height: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  // outline: 1px solid red;
}

section.main-page img.main-logo {
  width: 100%;
}

section.photowall-page img.main-logo {
  width: 25%;
  float: left;
}

section.photowall-page img.organizer {
  position: absolute;
  bottom: 0;
  left: 0;
}

button.menu,
button.close-menu {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 70px;
    height: 70px;
    background-color: transparent;
    outline: 0;
    border: 0;
    top: 25px;
    right: 20px;
    z-index: 2;
}

button.menu {
  display: none;
  background-image: url('../images/MENU.png');
}

button.close-menu {
  display: none;
  background-image: url('../images/MENU_CLOSE.png');
}

button.top-page {
  display: none;
  background-image: url('../images/ARROW_BTN.png');
  bottom: 20px;
  position: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 50px;
  height: 50px;
  background-color: transparent;
  outline: 0;
  border: 0;
  right: 20px;
}

button.top-page:hover {
  cursor: pointer;
}

button.fb-link,
button.ig-link {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 70px;
  height: 70px;
  background-color: transparent;
  outline: 0;
  border: 0;
}

button.fb-link {
  background-image: url('../images/MENU_FB.png');
}

button.ig-link {
  background-image: url('../images/MENU_IG.png');
}

button.menu:hover,
button.close-menu:hover,
button.fb-link:hover,
button.ig-link:hover {
  opacity: .61;
  cursor: pointer;
}

.progressbar-back {
  height: 20px;
  overflow: hidden;
  background: rgb(160, 160, 160);
  border-radius: 10px;
}

.progressbar-front {
	position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: #FFF;
  border-radius: 10px;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
   text-decoration: none;
}
.sub-menu,
.home-logo,
ul {
  display: inline-block;
  vertical-align: middle;
}

.sub-menu {
  width: 95%;
  height: 20%;
}

.home-logo {
  margin: 0 2.5%;
  width: 10%;
}

.sub-menu ul {
  width: 80%;
  // outline: 1px solid red;
} 

.sub-menu li {
  float: left;
  // display: inline-block;
  // margin: 0 3%;
  margin: auto;
  // outline: 1px solid yellow;
  text-align: center;
  width: 20%;
}

.sub-menu a {
  display: inline-block;
}

.sub-menu .menu-item * {
  text-align: center;
  color: rgb(128, 128, 128);
  letter-spacing: 0.2em;
}

.sub-menu .menu-item.work * {
  color: rgb(255, 217, 161);
}

.tw-item {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5em;
}

.en-item {
  font-size: 12px;
  letter-spacing: 0.25em;
}

section.news-content {
  width: 100%;
  .tab-block {
    width: 60%;
    margin: auto;
  }
  .tab-block button {
    background-color: transparent;
    color: #FFF;
    outline: 0;
    border: 0;
    padding: 0;
    margin-right: 40px;
    letter-spacing: 0.1em;
  }
  .tab-block .tab-menu button {
    font-size: 18px;
    padding-bottom: 5px;
    letter-spacing: 0.2em;
  }
  .tab-block .tab-menu button.active{
    border-bottom: 1px solid rgb(255, 217, 161);
  }
  .tab-block .tab-menu button:hover {
    cursor: pointer;
  }
  .tab-block .tab-sub-item {
    display: none;
  }
  .tab-block .tab-sub-item.active {
    display: block;
  }
  .tab-block .tab-sub-item button {
    font-size: 14px;
  }
  .tab-block .tab-sub-item button.active {
    color: rgb(255, 217, 161);
  }
  .tab-block .tab-sub-item button:hover {
    cursor: pointer;
  }
  .media-list {
    width: 64%;
    margin: 50px auto;
  }
  .media-list .single-media {
    width: 30%;
    height: 200px;
    margin-right: 2%;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .media-list .single-media:nth-child(3n) {
    margin-right: 0;
  }
}

section.concept-content {
  color: #FFF;
  width: 60%;
  margin: auto;
  background: 0;
  .tab-menu {
    display: inline-block;
    vertical-align: top;
    width: 15%;
  }
  .tab-menu button {
    border: 0;
    outline: 0;
    background-color: transparent;
    color:rgb(160, 160, 160);
    display: block;
    font-size: 18px;
    letter-spacing: 0.2em;
  }
  .tab-menu button:first-child {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .tab-menu button.active {
    color: rgb(255, 217, 161);
  }
  .tab-menu button:hover {
    cursor: pointer;
  }
  .article-block {
    display: none;
    width: 80%;
  }
  .article-block.active {
    display: inline-block;
  }
  .article-block h3 {
    margin-bottom: 50px;
    font-size: 24px;
    letter-spacing: 0.2em;
    line-height: 1.3em;
  }
  h3.specail-title {
    color: rgb(255, 217, 161);
  }
  .article-block p {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 5px;
  }
  .article-block#article-2 p {
    padding: 0 4% 0 6%;
    width: 90%;
  }
  .article-block#article-2 img {
    width: 100%;
  }
  .article-content {
    margin-bottom: 200px;
  }
  .motto-block {
    margin: 80px 0;
  }
  .motto-block .motto-title {
    letter-spacing: 0.2em;
    line-height: 1.3em;
  }
  .motto-from {
    text-indent: 4em;
    font-size: 20px;
    margin: 10px 0;
    letter-spacing: 0.1em;
  }
}

section.aboutus-block {
  display: block;
  width: 70%;
  margin-left: 20%;
  background: 0;
  * {
    color: #FFF;
    letter-spacing: 5px;
    line-height: 1.5;
  }
  button {
    font-size: 18px;
    background-color: transparent;
    outline: 0;
    border: 0;
    padding: 0;
    padding-bottom: 5px;
    margin-right: 30px;
  }
  button:last-child {
    margin-right: 0;
  }
  button.active {
    border-bottom: 1px solid rgb(255, 217, 161);
  }
  button:hover {
    cursor: pointer;
  }
  .aboutus-content {
    margin-top: 150px;
    display: none;
  }
  .aboutus-content.active {
    display: block;
  }
  .role-into-item * {
    font-size: 20px;
  }
  .intro-item-title {
    margin-top: 150px;
  }
  .team-intro-title,
  .aboutus-title {
    color: rgb(255, 217, 161);
    margin-bottom: 30px;
  }
  .project-item {
    font-size: 18px;
  }
  p {
    font-size: 20px;
  }
  .aboutus-content.aboutus-4 * {
    letter-spacing: 0.3em;
  }
  img {
    width: 50px;
    height: auto;
    margin-right: 25px;
  }
}

section.workshop-home {
  position: relative;
  height: 80%;
  * {
    color: #FFF;
  }
  .workshop-home-top {
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .workshop-home-left,
  .workshop-home-right {
    width: 49.5%;
    display: inline-block;
    vertical-align: top;
  }
  .workshop-home-left {
    width: 44.5%;
    padding-left: 5%;
  }
  .workshop-home-left .main-title {
    color: rgb(255, 217, 161);
    font-size: 36px;
    line-height: 1.5;
    letter-spacing: 0.2em;
  }
  .workshop-home-left p.description,
  .workshop-home-right p {
    font-size: 18px;
    letter-spacing: 5px;
    line-height: 1.75;
  }
  .workshop-home-right {
        padding-top: 15px;
  }
  .workshop-img-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
    font-size: 0;
   }
  .workshop-img-menu .workshop-img {
    width: 16.665%;
    // outline: 1px solid red;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.2em;
  }
  .workshop-img-menu .workshop-img:hover {
    cursor: pointer;
  }
  .workshop-img-menu .workshop-img.filter {
    filter: brightness(.5);
  }
  .workshop-img-menu .workshop-img.filter.animation {
    animation: maskFadeout;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  // @keyframes maskFadeout{
  //   0%   {filter: brightness(.6); width: 16.665;}
  //   25%  {filter: brightness(.7); width: 17%;}
  //   50%  {filter: brightness(.8); width: 18%;}
  //   75%  {filter: brightness(.9); width: 19%;}
  //   100% {filter: brightness(1); width: 20%;}
  // }
  @keyframes maskFadeout{
    0%   {filter: brightness(.6);}
    25%  {filter: brightness(.7);}
    50%  {filter: brightness(.8);}
    75%  {filter: brightness(.9);}
    100% {filter: brightness(1);}
  }
  .workshop-img-menu .workshop-img.filter.animation2 {
    animation: maskFadeIn;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  // @keyframes maskFadeIn{
  //   0%   {filter: brightness(1); width: 20%;}
  //   25%  {filter: brightness(.9); width: 19%;}
  //   50%  {filter: brightness(.8); width: 18%;}
  //   75%  {filter: brightness(.7); width: 17%;}
  //   100% {filter: brightness(.6); width: 16.665;}
  // }
  @keyframes maskFadeIn{
    0%   {filter: brightness(1);}
    25%  {filter: brightness(.9);}
    50%  {filter: brightness(.8);}
    75%  {filter: brightness(.7);}
    100% {filter: brightness(.6);}
  }
  .workshop-img-menu .workshop-img.workshop-1 { background-image: url(../images/workshop1.jpg); }
  .workshop-img-menu .workshop-img.workshop-2 { background-image: url(../images/workshop5.jpg); }
  .workshop-img-menu .workshop-img.workshop-3 { background-image: url(../images/workshop3.jpg); }
  .workshop-img-menu .workshop-img.workshop-4 { background-image: url(../images/workshop4.jpg); }
  .workshop-img-menu .workshop-img.workshop-5 { background-image: url(../images/workshop2.jpg); }
  .workshop-img-menu .workshop-img.workshop-6 { background-image: url(../images/workshop6.jpg); }
}

section.workshop-detail {
  position: relative;
  * {
    color: #FFF;
  }
  .workshop-detail-top {
    width: 80%;
    vertical-align: top;
    margin: auto;
  }
  .workshop-detail-left,
  .workshop-detail-right {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
  .workshop-detail-left {
    width: 35%;
    padding-left: 5%;
  }
  .workshop-detail-left .main-title {
    color: rgb(255, 217, 161);
    font-size: 36px;
    line-height: 1.5;
    letter-spacing: 0.2em;
  }
  .workshop-detail-left .description,
  .workshop-detail-right p {
    font-size: 18px;
    letter-spacing: 5px;
    line-height: 1.75;
  }
  .workshop-detail-right {
    padding-top: 15px;
  }
  .workshop-detail-menu {
    padding-left: 5%;
    margin-top: 150px;
  }
  .tab-sub-item button {
    background-color: transparent;
    color: #fff;
    outline: 0;
    border: 0;
    padding: 0;
    margin-right: 60px;
    font-size: 18px;
    letter-spacing: 0.2em;
  }
  .tab-sub-item button.active {
    color: rgb(255, 217, 161);
  }
  .tab-sub-item button:hover {
    cursor: pointer;
  }
  .video-block,
  .photo-list {
    display: none;
  }
  .video-block {
    width: 90%;
    margin: auto;
    .single-media {
      width: 100%;
      height: 100%;
    }
  }
  .photo-list {
    width: 90%;
    margin: 30px auto;
  }
  .photo-list .photoblock {
    width: 30%;
    height: 300px;
    margin-right: 2%;
    margin-bottom: 20px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter:grayscale(1);
  }
  .photo-list .photoblock:hover {
    filter: none;
  }
}

// Loading
.loading-block {
  @include center;
  width: get-vw(296px);
  height: get-vw(296px);
  svg text {
    font-size: 50px;
  }
}

.loading-block.hide {
  display: none;
}

section.pop-message {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 9;
  top: 0;

  .message-block {
    @include center;
    width: 60%;
    margin: auto;
    height: auto;
    background-color: transparent;
    border-radius: 7vw;
    color: #000;
    // padding: 5%;
    width: get-vw(800px);
    height: get-vw(500px);
    background-color: #EEE;
  }
  .message-block.loading {
    height: auto;
  }
  .message-notice {
    font-size: 6vw;
    padding-top: get-vw(64px);
    // height: get-vw(360px);
    height: 60%;
    color: #313537;
  }
  .message-notice .message-title {
    font-size: get-vw(70px);
    font-weight: bold;
  }
  .message-notice .message-content {
    font-size: get-vw(50px);
    line-height: get-vw(64px);
    padding: 2% get-vw(64px) 10% get-vw(64px);
    img {
      width: 60%;
    }
  }
  button.pop-close {
    display: none;
    position: static;
    color: #fff;
    background-color: transparent;
    width: 100%;
    height: get-vw(138px);
    // border: 2px solid #2a82d7;
    // border-radius: 6.5vw;
    font-weight: bold;
    font-size: 4.5vw;
    padding: 1vw;
    color: #313537;
    border-top: 1px solid #313537;
    // background-color: #01080d;
  }
  button.pop-close.active {
    display: block;
  }
}

section.collaboration-block {
  display: block;
  width: 50%;
  margin: auto;
  background: 0;
  height: auto;
  * {
    color: #fff;
    letter-spacing: 5px;
    line-height: 1.5;
  }
  button {
    font-size: 18px;
    background-color: transparent;
    outline: 0;
    border: 0;
    padding: 0;
    padding-bottom: 5px;
    margin-right: 30px;
  }
  button:last-child {
    margin-right: 0;
  }
  button.active {
    border-bottom: 1px solid rgb(255, 217, 161);
  }
  button:hover {
    cursor: pointer;
  }
  .collaboration-content {
    display: none;
    margin-top: 50px;
    padding-bottom: 150px;
  }
  .collaboration-content.collaboration-3 {
    margin-top: 150px;
  }
  .collaboration-content.active {
    display: block;
  }
  p {
    font-size: 16px;
  }
  .main-title {
    color: #ffd9a1;
    margin-bottom: 30px;
  }
  .sub-title {
    font-size: .7em;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .contact-block {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  img {
    width: 100%;
  }
  img.menu-img {
    width: 30%;
    height: auto;
    margin-bottom: 50px;
    filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.7));
  }
  img.half-width {
    display: inline-block;
    width: 48.5%;
  }
  img.margin-right5 {
    margin-right: 3%;
  }
  img.icon-img,
  img.icon-img2 {
    display: inline-block;
    margin-right: 20px;
    width: 50px;
    height: auto;
  }
  img.icon-img:hover {
    cursor: pointer;
  }
  .link-block {
    vertical-align: middle;
    * {
      vertical-align: middle;
    }
  }
  .link-title {
    display: inline-block;
    font-size: .7em;
    margin-right: 20px;
    vertical-align: middle;
  }
  .collaboration-content p.letterspacing-zero {
    letter-spacing: 0;
    text-align: justify;
    font-size: 18px;
    letter-spacing: 0.05em;
  }
  .collaboration-content p.right {
    text-align: right;
  }
}

section.special-footer {
  display: block;
  height: 80px;
  width: 100%;
  // outline: 1px solid red;
  position: relative;
}

@media (max-width: 320px) and (max-height: 480px) {
  
}
@media (min-width: 768px) {
  
}