@mixin size($w, $h) {
  width: $w;
  height: $h;
}

@mixin horizontal-center {
  left: 0;
  right: 0;
  margin: auto;
}

@mixin center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin background-image($w, $h) {
  width: $w;
  height: $h;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}